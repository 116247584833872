// extracted by mini-css-extract-plugin
export var caseContactFormSection = "bn_kz";
export var caseStudyBackground__lineColor = "bn_kq";
export var caseStudyHead__imageWrapper = "bn_kn";
export var caseStudyProjectsSection = "bn_kB";
export var caseStudyQuote__bgLight = "bn_kx";
export var caseStudyQuote__bgRing = "bn_kw";
export var caseStudyQuote__bgRingDark = "bn_kK";
export var caseStudyQuote__bottomVideo = "bn_kL";
export var caseStudySolution__ring = "bn_kt";
export var caseStudySolution__ringTwo = "bn_kv";
export var caseStudyVideo = "bn_kD";
export var caseStudyVideo__ring = "bn_kF";
export var caseStudy__bgDark = "bn_km";
export var caseStudy__bgLight = "bn_kl";